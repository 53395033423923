@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: rgb(238, 238, 238);
}

@font-face {
  font-family: SFBlack;
  src: url('./fonts/SF\ UI\ Display-Black.otf');
}

@font-face {
  font-family: SFBold;
  src: url('./fonts/SF\ UI\ Display-Bold.otf');
}

@font-face {
  font-family: SFRegular;
  src: url('./fonts/SF\ UI\ Display-Regular.otf');
}

@font-face {
  font-family: SFLight;
  src: url('./fonts/SF\ UI\ Display-Light.otf');
}